import React from 'react';
import { graphql, Link } from 'gatsby';
import PostListing from '../components/PostListing/PostListing';
import SingleColPageLayout from '../templates/single-col-page-layout';
import MetaTags from '../components/MetaTags';

const ArticlesPage = ({ data, path }) => {
  const postEdges = data.allMarkdownRemark.edges;
  return (
    <SingleColPageLayout>
      <MetaTags
        title="Articles"
        description="Articles on the subject of designing and building serverless applications"
        path={path}
      />
      <h1 className="text-center">All Articles by Date</h1>
      <p>
        This page lists all my long-form articles on building software with
        serverless.
      </p>
      <p>
        If you’re looking for my daily emails archive,{' '}
        <Link to="/emails/">go here</Link>.
      </p>
      <div className="listing-container">
        <div className="posts-container">
          <PostListing postEdges={postEdges} />
        </div>
      </div>
    </SingleColPageLayout>
  );
};

export default ArticlesPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "post" } } }
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            description
            tags
            categories
            date
          }
        }
      }
    }
  }
`;
